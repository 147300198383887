/*! GORDEEV MEDIA FIRM CSS */
/*! by @fR0u1 */

/*? Basic Styles */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
}

a {
  text-decoration: none;
}

:root {
  --main-color: #e24733;
  --bg-color: #1f1f1f;
}

body {
  max-width: 1200px;
  margin: 0 auto;
  color: white;
  background: var(--bg-color);
  padding: 20px;
}

/*? BUTTONS */

.btn,
.area {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  outline: none;
  border: none;

  /* height: 100%; */
  padding: 10px 20px;

  color: white;
  background-color: #e24733;
  box-shadow: 0 0 25px #e2483486;
  border: solid transparent;

  text-align: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 0 25px #e24733;
    transform: scale(1.03);
  }
}

.accept-btn {
  margin: 0;
  background-color: #e24734;
  box-shadow: 0 0 10px 10px #e2473433;

  &:hover {
    background-color: #24b93d;
    color: white;
    box-shadow: 0 0 15px 5px #3af01688;
  }
}

.dashed-btn {
  border: dotted 2px #e24733;
  background-color: #e2473338;
  box-shadow: 0 0 15px #e2473300;

  &:hover {
    border: dotted 2px #3385e2;
    background-color: #3379e270;
    box-shadow: 0 0 15px #338de294;
  }
}

.dashed-btn-alt {
  border: dotted 2px #63a84e;
  background-color: #33e23370;
  box-shadow: 0 0 15px #33e23394;

  &:hover {
    /* color: black; */
    border: dotted 2px #ffffff;
    background-color: #c7c7c770;
    box-shadow: 0 0 15px #ffffff94;
  }
}

.alt-btn {
  &:hover {
    border: solid white;
    background-color: #fff;
    color: #000000;
    box-shadow: 0 0 15px #ffffffa9;
  }
}

.white-btn {
  border: solid;
  border-color: #ffffff;
  color: white;
  background-color: #ffffff33;
  box-shadow: 0 0 15px 5px #ffffff13;

  &:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: black;
    box-shadow: 0 0 15px 15px #ffffff33;
  }
}

.alt-white-btn {
  border: solid;
  border-color: #ffffff;
  color: black;
  background-color: #ffffff;
  box-shadow: 0 0 15px#ffffff3b;

  &:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: black;
    box-shadow: 0 0 15px #ffffff;
  }
}

/*? Blocks */

.basic-row-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.centered-row-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.basic-box {
  display: flex;
  flex-direction: column;
  width: 310px;
  padding: 5px;
  text-align: center;
  margin: 0 auto;
  border: solid 1px #e24733;
  box-shadow: #e24733 0;
  box-shadow: 0px 0px 39px 10px #e2473386, inset 0px 0px 20px #e247336b;
}

.shr {
  font-size: 250px;
  margin: 0;
  color: #e24733;
  height: 250px;
  font-weight: 900;

  & p {
    color: white;
  }
}

.noBordShad {
  border: none;
  box-shadow: none;
  box-shadow: none;
}

.basic-input {
  margin: 5px;
  font-size: 15px;
  padding: 5px 10px;
  outline: none;
  background: #e2473356;
  color: #ffffff;
  border: 1px solid #e24733;
  transition: 0.3s ease;

  &:focus {
    background: #75332a56;
  }
}

.drag-n-drop2 {
  border: dotted 2px #33e23c;
  border-radius: 19px;
  background-color: #3fb976;
  box-shadow: 0 0 15px #42e23394;
  transition: 0.2s all ease-in-out;

  &:hover {
    border-radius: 0px;
    border: dotted 2px #ffffff;
    background-color: #418af88e;
    box-shadow: 0 0 15px #338de294;
    transform: scale(1.01);
  }
}

/*!!! GM STATS CUSTOMS */
/*!!! GM STATS CUSTOMS */
/*!!! GM STATS CUSTOMS */
/*!!! GM STATS CUSTOMS */
/*!!! GM STATS CUSTOMS */

.logo {
  display: block;
  width: 450px;
  margin: 150px auto;
}


.menuItem {
  margin: 15px;
}

.leftside,
.Center,
.rightSide,
.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.tt-btn {
  position: relative;
  background-color: black;
  box-shadow: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: black;
    box-shadow: none;
    transform: none;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg,
        rgb(255, 0, 85) 0%,
        rgb(0, 255, 255) 100%);
    filter: blur(15px);
    z-index: -10;
    transition: all 0.3s ease-in-out;
  }

  .T1 {
    font-weight: 900;
    color: rgb(255, 0, 85);
  }

  .T2 {
    font-weight: 900;
    color: rgb(0, 255, 255);
  }

  &:hover::after {
    filter: blur(25px);
    transform: scale(1.2);
  }

  .inst-btn {
    background: linear-gradient(90deg, #f9ce34 0%, #ee2a7b 50%, #6228d7 100%);
  }
}

.authreg {
  font-size: 15px;
  color: white;
  padding: 5px 10px;
  font-weight: 900;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  margin: 15px 5px;

  &:hover {
    color: black;
    background-color: #e24733;
  }
}

.active-authreg {
  background-color: #e24733;
}

.logo-h {
  width: 200px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }
}

.menu_item {
  font-size: 13px;
  font-weight: 500;
  margin: 7px;
  text-align: center;
  padding: 5px 7px;

  & * {
    cursor: pointer;
  }
}

.svgs {
  margin-left: 5px;
  margin-right: 5px;
}

.header {
  position: relative;
  z-index: 999;
  margin-top: 15px;
  max-width: 1200px;
  min-width: 900px;
  justify-content: space-between;
  padding: 0;
}

.drag-n-drop-c1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  justify-content: center;
  width: 500px;
  height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 110%);
  border: dotted 2px #ffffff;
  border-radius: 19px;
  background-color: #ffffff31;
  box-shadow: 0 0 25px #ffffff70;
  transition: 0.2s all ease-in-out;

  &:hover {
    border-radius: 0px;
    border: dotted 2px #3385e2;
    background-color: #3379e270;
    box-shadow: 0 0 15px #338de294;
    transform: translate(-50%, 110%) scale(1.01);
  }
}

.svgAddFile {
  max-width: 35px;
  margin: 0 5px;
}

.errArea {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: dotted 2px #d1171794;
  border-radius: 19px;
  background-color: #97212170;
  box-shadow: 0 0 15px #e2333394;
}

.successArea {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: dotted 2px #17d12094;
  border-radius: 19px;
  background-color: #21972b70;
  box-shadow: 0 0 15px #33e25f94;
}

.area1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  outline: none;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  width: 100%;
  height: 90%;
}

.workspace {
  position: relative;
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
}

.lastTableName {
  user-select: none;
}

.currentTable {
  background-color: #ffffff3f;
  border-radius: 15px;
  border: dotted 2px #fff;
  padding: 10px 15px;
  width: 500px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.xlsxSvg {
  margin: 0px 5px;
}

.XSvg {
  fill: #ff8b8b;
  align-self: center;
  margin-left: 55px;
  transition: all 0.3s ease-in-out;
  transform: scale(0.8);
  overflow: auto;
  position: relative;

  &:hover {
    fill: #d13636;
    cursor: pointer;
    transform: rotateZ(90deg) scale(1.1);
  }
}

.selectFiltrationType {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  margin-top: 50px;
}

.filter-radio {
  width: 100%;
  border: solid 2px #e24733;
  background-color: #e2473338;
  box-shadow: 0 0 15px #e2473300;
  transition: all 0.1s ease-in-out;

  &:hover {
    border: solid 2px #e24733;
    background-color: #e24733;
    box-shadow: 0 0 15px #e2473300;
  }
}

.activeFilter {
  transition: all 0.1s ease-in-out;
  background-color: #e24733;
  box-shadow: 0 0 25px #e24733;
}

.inputs {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border: none;
  box-shadow: none;
  width: 500px;
}

.inputsChild {
  display: flex;
  width: 100%;
  height: 40px;
}

.cross-alt {
  font-size: 15px;
  padding: 0 5px;
  margin: 5px;
  height: auto;

  border: solid 2px #e24733;

  &:hover {
    background: #e2473356;
    box-shadow: 0 0 25px #e24733;
    transform: scale(1.03);
  }
}

.alt-input {
  width: 100%;
  margin: 5px;
}

.inactiveSend {
  opacity: 0.5;
  position: relative;
  border-color: #fff;
  background-color: transparent;
  user-select: none;
  box-shadow: none;
  cursor: not-allowed;
}

.inactiveSend:hover {
  background-color: #8a000057;
  border-color: #8a000057;
  transform: none;
  box-shadow: none;
}

.spinnerBlock {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(10px);
  z-index: 200;
  transition: all 2s ease-out;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
}

.modalWin2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #1f1f1f;
  padding: 35px;
  outline: none;
}

.modalWinOverlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #0000001a;
  backdrop-filter: blur(15px);
  outline: none;
}

.downloadTable {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  margin-bottom: 25px;
}

.clickable {
  margin-top: 50px;
  margin-bottom: -55px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
    background-color: #32c0513f;
    border: dotted 2px #35f355;
  }
}

@keyframes loadAnim {

  0%,
  100% {
    transform: scale(1);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.05);
    opacity: 0.7;
  }
}

.loading {
  animation: loadAnim 3s infinite;
}

.gifLoading {
  filter: invert(1);
}

@keyframes loopCircle {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loadingItm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.gifLoading {
  transition: all 0.3s ease-in-out;
  /* animation: 2s loopCircle infinite; */
  background-blend-mode: screen;
  background-color: transparent;
}

.reportFormCL {
  border: none;
  box-shadow: none;
  width: 500px;
  transition: 0s ease;
}

.ext {
  position: relative;
  width: 100%;
  margin: 5px 55px;
  padding: 25px 5px;
}

.noAnim {
  transition: 0s ease;
  max-width: 468px;
  min-width: 468px;
  max-height: 200px;
  min-height: 50px;
}

.LogOut {
  margin-right: 0;
}

.modalWinOverlay2 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #1f1f1f;
  z-index: 700;
  /* backdrop-filter: blur(15px); */
}

#filePickerReport {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:hover {
    border: dotted 2px #e24733;
    background-color: #e2333370;
    box-shadow: 0 0 15px #e24733;
  }

  &::file-selector-button {
    background-color: #e24733;
    box-shadow: 0 0 25px #e2483486;
    color: white;
    border: solid transparent;
    padding: 0.5em 0.8em;
    cursor: pointer;
  }
}

.errPg {
  display: flex;
  justify-content: center;
  align-items: center;

}

.errPglogo {
  margin: 0;
  margin-top: 200px;
}

.errPgBord {
  padding: 7px 15px;
  border: solid 1px #e24733;
  box-shadow: #e24733 0;
  box-shadow: 0px 0px 39px 10px #e2473386, inset 0px 0px 20px #e247336b;
}


.centered {
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
}

@keyframes serStat {
  0% {
    opacity: 100%;
    transform: scale(0);
  }

  100% {
    opacity: 0%;
    transform: scale(3);
  }
}


.animationServerStatus {
  animation: serStat 1s infinite;
  transition: all ease-in-out .3s;
}

.right-side,
.left-side {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */

}

.left-side {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.right-side {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  z-index: 699;
}

.TreeNode {
  display: flex;
  flex-direction: row;
  border: 2px #e24734 solid;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  /* width: 100%; */
  transition: all .2s ease;
  cursor: pointer;

  &:hover {
    background-color: #e24734;
  }
}

.TreeNode_active {
  background-color: #114785;
  border: 2px #3385e2 solid;

  &:hover {
    background-color: #005ac0;
    border: 2px #3385e2 solid;
  }
}

.exelBlockbtn {
  transition: all .3s ease-in-out;
  width: 100%;
  padding: 5px 10px;
  background-color: #158b29;
  box-shadow: 0 0 0px #14d333;

  &:hover {
    box-shadow: 0 0 15px #14d3345b;
    background-color: #158b29;
  }
}

.addictstyleEB {
  &:hover {
    opacity: 1;
  }
}

.addictEB {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 30px;
}

.textAddFile {
  max-width: 200px;
  cursor: pointer;
  margin-left: 5px;
}

.label-treenode {
  cursor: pointer;
}

.addFileImg {
  width: 30px;
}

.tree-main,
.lastReports {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  max-width: 350px;
  max-height: 300px;
  overflow: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 7px;
    /* Ширина скроллбара */
    border-radius: 15px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    border-radius: 15px;
    cursor: pointer;
    background: rgba(226, 71, 52, 0.1);
    /* Цвет трека скроллбара */
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 15px;
    background: rgb(226, 71, 52);
    /* Цвет ползунка скроллбара */
  }

  &::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: rgba(226, 71, 52, 0.5);
    /* Цвет ползунка скроллбара при наведении */
  }

}

.tree-main {
  min-height: 250px;
}

.ugolochek {
  display: flex;
  transition: all .3s ease;
  width: 25px;
  height: 25px;
  /* border-bottom: solid 1px red;
  border-right: solid 1px red; */
}


.create-new-upload {
  padding: 3px 15px;
}

.uploadQuarter {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(31, 31, 31, 1);
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  min-height: 500px;
  height: 100%;
  z-index: 700;
}

.uploadInputs {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 600px;
}

#yearpicker {
  max-width: 227px;
}

.selector-quarter {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: start;
}

.selectedbtn {
  color: black;
  font-weight: 900;
  background: white;
}

.cross {
  cursor: pointer;
  position: absolute;
  right: -35px;
  top: -35px;
  font-size: 50px;
  color: white;

  &:hover {
    transform: rotate(180deg);
  }
}

.cross-sec {
  display: flex;
  transform: rotate(45deg);
  margin-left: 15px;
  cursor: pointer;
  transition: all .2s ease;

  &:hover {
    transform: rotate(135deg);
  }
}

.lastReports {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.lastReportsMain {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.rslabel {
  font-weight: bold;
  margin-bottom: 5px;
}

.reportBlock {
  transition: all .2s ease;
  cursor: pointer;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  max-width: 300px;
  padding: 5px 10px;
  background-color: #28aa3e;

  &:hover {
    background-color: #28aa3e81;
  }
}

.labelBlock {
  padding: 5px 10px;
  background-color: #e24734;
}

.zipReportBlock {
  background-color: #3385e2;

  &:hover {
    background-color: #3385e28f;
  }
}


.reportLabel {
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #1f1f1f67;
  z-index: 500;
}

.deleteReport {
  background: #e24734;
  font-size: 25px;
  line-height: 15px;
  height: 15px;
  width: 15px;
  margin: 0;
  padding: 0;

  &:hover {
    color: #e24734;
    background: #f5f5f5;
  }
}

.delQu {
  display: block;
  margin-left: auto;
  position: absolute;
  right: 10px;
}

.err {
  margin: 0 auto;
  /* margin-bottom: 15px; */
  line-height: 100%;
}

.suuper-left-side {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  max-width: 600px;
  margin: 55px auto;
  margin-top: 15px;
  position: relative;
  
}

.news_text {
  /* line-height: 100%; */
}

.newsBlock {
  display: flex;
  flex-direction: row;
  border: solid 2px #e24733;
  padding: 15px;
}

.newsTitle {
  color: #e24733;
  margin: 0;
}
.newsText {
  margin: 0;
}

.iconNews {
  max-width: 30px;
  margin-right: 15px;
}

.abs_cross {
  position: absolute;
  top: 15px;
  right: 20px;
}